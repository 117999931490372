import React from "react";
import InnerPage from "../../components/InnerPage/InnerPage";

function Stranger(props) {
  const tableauObjets = [
    {
      title: "Obtention et renouvellement de titres de séjour",
      content:
        "Que vous soyez membre d'une famille française ou européenne, réfugié, salarié, entrepreneur ou étudiant, le cabinet vous accompagne dans vos démarches pour obtenir ou renouveler votre titre de séjour, en mettant à profit ses compétences et sa connaissance des pratiques préfectorales.\n\nLe cabinet intervient également devant les tribunaux administratifs pour contester les refus de titre de séjour ou les décisions d'éloignement (OQTF).",
    },
    {
      title: "Regroupement familial et Réunification familiale",
      content:
        "Si vous êtes en situation régulière en France, vous pouvez faire venir votre conjoint et vos enfants à travers une procédure de regroupement familial ou de réunification familiale.\n Cette démarche est soumise à des critères relatifs à votre logement et à vos ressources financières.\nLe cabinet vous assiste dans l'ensemble de votre démarche auprès des autorités compétentes, ainsi que dans l'éventualité d'un recours en cas de refus.",
    },
    {
      title: "Acquisition de la Nationalité Française",
      content:
        "Si vous êtes jeune né en France de parents étrangers, conjoint de Français, enfant adopté ou recueilli par une personne ou institution française, ou bénéficiaire de la possession d'état de Français, vous êtes concerné par une procédure déclarative.Si vous résidez en France depuis 5 ans ou êtes diplômé d'un établissement français et justifiez d'une intégration professionnelle, vous pouvez demander la naturalisation.Le cabinet vous accompagne dans la préparation de votre dossier et pour l'entretien d'assimilation en cas de naturalisation par décret.",
    },
    // {
    //   title: "Visas",
    //   content: "Conditions d'obtention, conseils et recours en cas de refus",
    // },

    // {
    //   title: "Asile",
    //   content:
    //     "Assistance devant l’OFPRA et la CNDA, procédure de réexamen de la demande d’asile",
    // },

    // {
    //   title: "Travail",
    //   content:
    //     "Autorisation de travail, contrats de travail pour les étrangers, régularisation liée à l'emploi",
    // },

    // {
    //   title: "Consultation Personnalisée",
    //   content:
    //     "Services de consultation juridique, analyse individualisée des situations, conseils personnalisés",
    // },
  ];
  return (
    <div>
      <div>
        <InnerPage
          InnerTitle="Droit des étrangers"
          InnerSubTitle="Le cabinet de Maître Doré accompagne ses clients dans toutes les démarches liées au séjour, au travail et au regroupement familial. Il intervient également en cas de contentieux, pour contester les décisions administratives telles que le refus de visa, de titre de séjour ou les mesures d’éloignement du territoire français. Grâce à ses compétences en droit des étrangers, le cabinet soutient ses clients face aux difficultés rencontrées lors de leur séjour en France, en assurant une défense rigoureuse devant les juridictions compétentes."
          InnerBanner="./images/stranger_compress.png"
          InnerImage="/images/stager-image_compress.jpg"
          InnerDropdown={tableauObjets}
        />
      </div>
    </div>
  );
}

export default Stranger;
