import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import "./ContactForm.css"; // Fichier CSS pour le style du formulaire
import { Checkbox } from "@mui/material";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    console.log(userEmail);
    if (!validateEmail(userEmail)) {
      setAlertEmail(true);
      setIsSubmitted(false);
      return;
    }
    emailjs
      .sendForm(
        "service_sespgbn",
        "template_u4kngki",
        form.current,
        "f3RgRAV7cR38cW2a4"
      )
      .then(
        (result) => {
          setIsSubmitted(true);
          setAlertEmail(false);

          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const validateEmail = (email) => {
    // Expression régulière pour valider le format d'un e-mail
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="Contact">
      <div className="contact-container">
        <form ref={form} onSubmit={sendEmail}>
          <h2>Contact</h2>
          <p>
            Vous pouvez contacter facilement le cabinet en remplissant le
            formulaire ci-contre. Une réponse vous sera donnée dans les
            meilleurs délais.
          </p>
          <div className="form-group-name">
            <div className="form-name">
              <label htmlFor="name">Nom :</label>
              <input type="text" name="name" required />
            </div>
            <div className="form-name">
              <label htmlFor="surname">Prénom :</label>
              <input type="text" name="surname" required />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email :</label>
            <input
              type="email"
              name="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
            {alertEmail && (
              <p className="valiation-error">
                L'adresse mail n'est pas correct{" "}
              </p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="message">Message :</label>
            <textarea id="message" name="message" required />
          </div>
          <div className="mention-rgpd">
            {" "}
            <Checkbox
              value="checkedA"
              inputProps={{ "aria-label": "Checkbox A" }}
              required
            />
            <p>
              En cochant cette case, vous consentez à la collecte et au
              traitement de vos données personnelles par le cabinet de Maître
              Camille Doré dans le but de traiter votre demande conformément à
              notre{" "}
              <Link to="/confidentialité">
                <span className="option">politique de confidentialité</span>
              </Link>{" "}
            </p>{" "}
          </div>

          <button className="CTA" type="submit">
            Envoyer
          </button>
          {isSubmitted && (
            <p className="valiation">Votre message a été envoyé </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
