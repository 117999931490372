import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import Nav from "../../components/Nav/Nav";
import PublicIcon from "@mui/icons-material/Public";
// import GavelIcon from "@mui/icons-material/Gavel";
// import HouseIcon from "@mui/icons-material/House";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import ContactForm from "../../components/Contact/ContactForm";
import axios from "axios";

function Home(props) {
  const [allPost, setAllPost] = useState([]);
  const [animate, setAnimate] = useState(false);
  const elementRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const elementPosition = elementRef.current.getBoundingClientRect().top;

        // La valeur 100 représente la distance à partir du haut de la page où l'animation devrait commencer
        if (elementPosition < window.innerHeight - 100) {
          setAnimate(true);
        } else {
          setAnimate(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elementRef]);

  useEffect(() => {
    axios
      .get("https://api.dore-avocat.fr/api/post")
      .then((response) => {
        setAllPost(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="banner">
        <Nav />
        <div className="banner-description">
          <h1>Maître Camille Doré, avocate au barreau de Lille </h1>
          {/* <p>Le cabinet intervient en droit des étrangers à Lille et à Paris</p> */}
        </div>
      </div>
      <div className="bio">
        <div className="bio-wrapper">
          <h2> Bienvenue sur le site de Maître Camille Doré</h2>
          <p>
            Le cabinet intervient en droit des étrangers à Lille et à Paris.
          </p>

          <Link to="apropos">
            <div className="CTA">Voir plus</div>
          </Link>
        </div>
        <div className="bio-photo">
          <img src="./images/bio.jpg" alt="bio"></img>
        </div>
      </div>
      <div className="xp">
        <div className="title-subtile">
          <h2>Domaine d'intervention </h2>
          {/* <p>
            Explorez les compétences de Maître Camille Doré, une avocate engagée
            et passionnée par la protection des droits fondamentaux. Elle
            intervient en droit des étrangers
          </p> */}
        </div>
        <div className={`xp-wrapper`}>
          <div className="xp-card">
            <Link to="./étranger">
              <PublicIcon />
              <h3 className="xp-title"> Droit des étrangers</h3>{" "}
              <p>
                Conseils pour les démarches administratives liées à
                l'immigration et à la protection des droits.
              </p>
            </Link>
          </div>
          {/* <div className="xp-card">
            <Link to="./pénal">
              <GavelIcon />
              <h3 className="xp-title"> Droit Pénal</h3>{" "}
              <p>
                Défense juridique, préservation des droits fondamentaux devant
                la justice.
              </p>
            </Link>
          </div> */}
          {/* <div className="xp-card">
            <Link to="./famille">
              <HouseIcon />
              <h3 className="xp-title"> Droit de la famille</h3>{" "}
              <p>
                Solutions juridiques personnalisées pour les questions de
                divorce, garde d'enfants et pension alimentaire.
              </p>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className={`rdv ${animate ? "animer" : ""}`} ref={elementRef}>
        <h2>Rendez-vous</h2>
        <p>
          Prenez rendez-vous avec Maître Camille Doré pour obtenir des conseils
          juridiques personnalisés et confidentiels. Elle comprend l'importance
          d'une assistance juridique adaptée à vos besoins. Que vous ayez besoin
          de conseils, de représentation juridique ou de clarifications sur vos
          droits, son cabinet est à votre service pour vous accompagner et vous
          défendre avec dévouement et professionnalisme.
        </p>
        <Link to="rdv">
          <div className="CTA">Voir plus</div>
        </Link>
      </div> */}
      <div className="blog">
        <div className="title-subtile">
          <h2>Blog</h2>
          <p>
            Cette rubrique a pour objectif de partager des articles et des
            réflexions sur les problématiques juridiques liées à l'immigration,
            les droits des étrangers et les actualités dans ce domaine. Les
            articles sont rédigés dans une approche pédagogique, afin de fournir
            des informations utiles à ceux qui rencontrent des difficultés
            administratives ou juridiques.
          </p>
        </div>
        {allPost.length === 0 ? (
          <p className="blog-section-null">
            {" "}
            Actuellement, le blog ne comporte aucun article. Nous vous invitons
            à revenir ultérieurement pour découvrir nos prochaines publications.
          </p>
        ) : (
          <div className="blog-section">
            {allPost.slice(0, 6).map((item, i) => (
              <div className="dashboard-card">
                <Card card={item} key={i} />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="contact-section">
        <ContactForm />
      </div>
    </div>
  );
}

export default Home;
